<template>
  <v-dialog v-model="dialog" scrollable max-width="500" @click:outside="reset">
    <v-card>
      <v-card-title>{{
        isEditing ? "Edit " + range.day_text : "Add a New Range"
      }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="save" method="post" id="range-form2">
          <v-switch
            label="Is the pool open on this day?"
            v-model="fields.is_open"
            inset
            color="green"
          ></v-switch>
          <div class="d-flex align-center mb-6" v-if="fields.is_open">
            <v-text-field
              label="From"
              v-model="fields.from_time"
              outlined
              hide-details
              background-color="white"
              type="time"
              class="datetime-selector"
            ></v-text-field>
            <span class="ml-4 mr-4">to</span>
            <v-text-field
              label="To"
              v-model="fields.to_time"
              outlined
              hide-details
              background-color="white"
              type="time"
              class="datetime-selector"
            ></v-text-field>
          </div>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn text @click="reset">Close</v-btn>

        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="range-form2"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      range: {},
      fields: {
        from_time: null,
        to_time: null,
        is_open: true,
      },
    };
  },

  methods: {
    open(range = null) {
      if (range !== null) {
        this.range = range;
        this.isEditing = true;
        this.fields.to_time = range.to_time;
        this.fields.from_time = range.from_time;
        this.fields.is_open = range.is_open;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.rangeId = this.range.id;
      }

      this.$store
        .dispatch("arnprior/poolStore/saveCore", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.range = {};
      this.fields = {
        from_time: null,
        to_time: null,
        is_open: true,
      };
    },
  },
};
</script>
