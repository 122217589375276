<template>
  <v-dialog v-model="dialog" scrollable max-width="500" @click:outside="reset">
    <v-card>
      <v-card-title
        >{{ isEditing ? "Edit" : "Add a New" }} Exception</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="save" method="post" id="range-form">
          <v-text-field
            label="Exclusion Name"
            v-model="fields.exclusion_name"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-select
            label="This Exception will be"
            :items="types"
            v-model="fields.type"
            outlined
            background-color="white"
          ></v-select>
          <div v-if="showDaySelectors">
            <v-select
              label="Day"
              :items="days"
              v-model="fields.day"
              outlined
              hide-details
              background-color="white"
              class="mb-6"
            ></v-select>
            <v-switch
              label="Does this exception occur during certain dates?"
              v-if="fields.type === 'Every'"
              v-model="showDateSelectors"
              inset
              color="green"
            ></v-switch>
          </div>
          <div class="d-flex align-center mb-6 mt-2" v-if="showDateSelectors">
            <v-text-field
              label="From Date"
              v-model="fields.from_date"
              outlined
              hide-details
              background-color="white"
              type="date"
              class="datetime-selector"
            ></v-text-field>
            <span class="ml-4 mr-4">to</span>
            <v-text-field
              label="To Date"
              v-model="fields.to_date"
              outlined
              hide-details
              background-color="white"
              type="date"
              class="datetime-selector"
            ></v-text-field>
          </div>
          <v-switch
            label="Does this exception occur during certain times?"
            v-model="showTimeSelectors"
            inset
            color="green"
          ></v-switch>
          <div class="d-flex align-center mb-6 mt-2" v-if="showTimeSelectors">
            <v-text-field
              label="From Time"
              v-model="fields.from_time"
              outlined
              hide-details
              background-color="white"
              type="time"
              class="datetime-selector"
            ></v-text-field>
            <span class="ml-4 mr-4">to</span>
            <v-text-field
              label="To Time"
              v-model="fields.to_time"
              outlined
              hide-details
              background-color="white"
              type="time"
              class="datetime-selector"
            ></v-text-field>
          </div>
          <v-switch
            label="Can the pool be booked during this exception?"
            v-model="fields.bookable"
            inset
            color="green"
          ></v-switch>
          <!-- <v-select
            label="Reason for this exception?"
            :items="categories"
            v-model="fields.category"
            outlined
            background-color="white"
          ></v-select> -->
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn text @click="reset">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="range-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { arnprior } from "@/modules/arnprior/mixins";

export default {
  mixins: [arnprior],

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      showDateSelectors: false,
      showTimeSelectors: false,
      showDaySelectors: false,
      range: {},
      types: ["Between", "Every"],
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      categories: ["Swim School", "Water Babies"],
      fields: {
        type: "Between",
        from_date: null,
        to_date: null,
        from_time: null,
        to_time: null,
        day: null,
        bookable: true,
        category: null,
        exclusion_name: null,
      },
    };
  },

  methods: {
    open(range = null, duplicate = false) {
      if (range !== null) {
        if (duplicate === false) {
          this.range = range;
          this.isEditing = true;
        }

        this.fields.to_date = range.to_date;
        this.fields.from_date = range.from_date;
        this.fields.to_time = range.to_time;
        this.fields.from_time = range.from_time;
        this.fields.bookable = range.is_bookable;
        this.fields.exclusion_name = range.exclusion_name;

        if (range.range_type === "between") {
          this.fields.type = "Between";
        } else {
          this.fields.type = "Every";
          this.fields.day = this.ucfirst(range.range_type);
          this.showDaySelectors = true;
        }

        if (range.has_dates) {
          this.showDateSelectors = true;
        }

        if (range.has_times) {
          this.showTimeSelectors = true;
        }
      }

      if (this.fields.type === "Between") {
        this.showDateSelectors = true;
      } else {
        this.showDaySelectors = true;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: {
          has_dates: this.showDateSelectors,
          from_date: this.fields.from_date,
          to_date: this.fields.to_date,
          has_times: this.showTimeSelectors,
          from_time: this.fields.from_time,
          to_time: this.fields.to_time,
          is_bookable: this.fields.bookable,
          exclusion_name: this.fields.exclusion_name,
        },
      };

      if (this.fields.type === "Every") {
        payload.fields.type = this.fields.day.toLowerCase();
      } else {
        payload.fields.type = "between";
      }

      if (this.isEditing) {
        payload.rangeId = this.range.id;
      }

      this.$store
        .dispatch("arnprior/poolStore/saveRange", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.showDateSelectors = false;
      this.showTimeSelectors = false;
      this.showDaySelectors = false;
      this.range = {};
      this.fields = {
        type: "Between",
        from_date: null,
        to_date: null,
        from_time: null,
        to_time: null,
        day: null,
        bookable: true,
        category: null,
        exclusion_name: null,
      };
    },
  },

  computed: {
    chosenRangeType() {
      return this.fields.type;
    },
  },

  watch: {
    chosenRangeType(value) {
      if (value === "Between") {
        this.showDateSelectors = true;
        this.showDaySelectors = false;
      } else {
        if (this.range !== {}) {
          if (this.range.has_dates) {
            this.showDateSelectors = true;
          } else {
            this.showDateSelectors = false;
          }
        } else {
          this.showDateSelectors = false;
        }

        this.showDaySelectors = true;
      }
    },
  },
};
</script>
