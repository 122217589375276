<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center" class="mb-2">
        <v-col cols="auto" class="pr-12">
          <h1>Timetable</h1>
        </v-col>
      </v-row>
      <v-card outlined class="mb-6">
        <v-card-title class="grey lighten-4"> Core Open Hours </v-card-title>
        <v-divider></v-divider>
        <v-simple-table>
          <thead>
            <tr>
              <th>Day</th>
              <th>Time Range</th>
              <th class="text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in core_hours" :key="index">
              <td class="pt-2 pb-2" v-text="item.day_text"></td>
              <td class="pt-2 pb-2">
                <div class="d-flex align-center" v-if="item.is_open">
                  <span class="font-weight-bold">{{ item.from_time }}</span>
                  <span class="ml-4 mr-4">to</span>
                  <span class="font-weight-bold">{{ item.to_time }}</span>
                </div>
                <div v-else>Closed</div>
              </td>
              <td class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      @click="$refs.rangeDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
      <v-card outlined>
        <v-overlay :value="priorityloading" absolute z-index="2">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-title class="grey lighten-4">
          <div>
            <div>Exceptional Hours</div>
            <div class="text-caption">
              To prioritise these exceptions, you can do this by dragging the
              rows to their desired priority level.
            </div>
            <div class="text-caption">
              The order for priority is Top = Highest & Bottom = Lowest.
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="success"
            @click="$refs.exceptionDialog.open()"
          >
            <v-icon left>mdi-plus</v-icon>Add Range
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-simple-table>
          <thead>
            <tr>
              <th></th>
              <th>Occurs</th>
              <th>Exclusion Name</th>
              <th>Is Bookable?</th>
              <th class="text-right">Actions</th>
            </tr>
          </thead>
          <draggable v-model="exceptional_hours" tag="tbody">
            <tr v-for="(item, index) in exceptional_hours" :key="index">
              <td>
                <v-icon small class="page__grab-icon">mdi-arrow-all</v-icon>
              </td>
              <td class="pt-2 pb-2">
                <span v-if="item.range_type !== 'between'"
                  >Every&nbsp;<strong>{{ ucfirst(item.range_type) }}</strong
                  >&nbsp;</span
                ><span v-if="item.has_dates"
                  >Between&nbsp;<strong
                    >{{ formatDate(item.from_date) }}&nbsp;to&nbsp;{{
                      formatDate(item.to_date)
                    }}</strong
                  >&nbsp;</span
                ><span v-if="item.has_times"
                  >from&nbsp;<strong
                    >{{ item.from_time }}&nbsp;until&nbsp;{{
                      item.to_time
                    }}</strong
                  ></span
                >
              </td>
              <td>{{ item.exclusion_name }}</td>
              <td>
                <v-chip small label color="success" v-if="item.is_bookable"
                  >Yes</v-chip
                >
                <v-chip small label color="error" v-else>No</v-chip>
              </td>
              <td class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="yellow lighten-3 yellow--text text--darken-3"
                      v-on="on"
                      @click="$refs.exceptionDialog.open(item, true)"
                    >
                      <v-icon x-small>mdi-content-duplicate</v-icon>
                    </v-btn>
                  </template>
                  <span>Duplicate</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="ml-2"
                      @click="$refs.exceptionDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      class="ml-2"
                      @click="openDelete(item)"
                    >
                      <v-icon x-small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </draggable>
        </v-simple-table>
        <v-divider></v-divider>
        <v-card-text class="text-right">
          <v-btn
            depressed
            color="success"
            @click="$refs.exceptionDialog.open()"
          >
            <v-icon left>mdi-plus</v-icon>Add Range
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
    <range-dialog ref="rangeDialog" />
    <exception-dialog ref="exceptionDialog" />
    <v-dialog v-model="deleteRange.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Range</v-card-title>
        <v-card-text>Are you sure you wish to delete this range?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteRange.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.range-selector {
  max-width: 200px;
}

.priority-selector {
  max-width: 100px;
}

.datetime-selector {
  max-width: 200px;
}
</style>

<script>
import Draggable from "vuedraggable";
import { arnprior } from "../../../mixins";
import RangeDialog from "./components/RangeDialog.vue";
import ExceptionDialog from "./components/ExceptionDialog.vue";

export default {
  mixins: [arnprior],

  components: {
    Draggable,
    RangeDialog,
    ExceptionDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Swimming Pool",
          disabled: true,
        },
        {
          text: "Timetable",
          disabled: true,
        },
      ],
      deleteRange: {
        dialog: false,
        range: {},
        loading: false,
      },
      priorityloading: false,
    };
  },

  computed: {
    core_hours() {
      return this.$store.getters["arnprior/poolStore/coreHours"];
    },

    exceptional_hours: {
      get() {
        return this.$store.getters["arnprior/poolStore/exceptionalHours"];
      },
      set(hours) {
        const displayOrder = hours.map((h) => h.id);

        this.priorityloading = true;
        this.$store.commit("showHideLoader", true);

        this.$store
          .dispatch("arnprior/poolStore/updatePriorities", {
            appId: this.$route.params.id,
            displayOrder,
          })
          .then(() => {
            this.priorityloading = false;
            this.$store.commit("showHideLoader", false);
          })
          .catch(() => {
            this.priorityloading = false;
            this.$store.commit("showHideLoader", false);
          });
      },
    },
  },

  methods: {
    openDelete(range) {
      this.deleteRange.range = range;
      this.deleteRange.dialog = true;
    },

    resetDelete() {
      this.deleteRange.dialog = false;
      this.deleteRange.range = {};
      this.deleteRange.loading = false;
    },

    saveDelete() {
      this.deleteRange.loading = true;

      this.$store
        .dispatch("arnprior/poolStore/deleteRange", {
          appId: this.$route.params.id,
          rangeId: this.deleteRange.range.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteRange.loading = false;
        });
    },
  },
};
</script>
